@use "../../utils/variables" as v;
.experience-company{
    font-size: 28px;
    display: inline;
    color: v.$colorC;
}
.experience-divider{
    display: inline;
    font-size: 28px;
}
.experience-title{
    display: inline;
    font-size: 21px;
    color: v.$colorA;
    position: relative;
    top: -3px;
}
.experience-item{
    margin-left: 10px;
    margin-right: 10px;
}
.experience-content{
    margin-left: 20px;
    margin-right: 20px;
    & a{
        color: v.$colorC;
        font-size: 1.1rem;
        text-decoration: none;
        font-weight: 500;
        &:hover{
            color: v.$colorCTL;
        }
    }
    & strong{
        color: v.$colorC;
    }
}