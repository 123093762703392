@use "./utils/variables" as v;
*:not(svg) {
  color: #ffffffc0;
}
body {
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: v.$backgroundA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h2,h1{
  font-family: 'Times New Roman', Times, serif;
  font-size: 36px;
  text-align: center;
  scroll-margin: 180px;
  margin-left: 10px;
  margin-right: 10px;
}
.revealed{
  & h2, & h1{
    scroll-margin: 30px;
  }
}
h2, .highlight{
  color: v.$colorC;
}
.content-section{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 1s ease;
  z-index: 0;
  &.revealed{
    transform: translateY(0px);
    opacity: 1;
    & .slide-in-from-right{
      transform: translateX(0px);
    }
  }
  & .slide-in-from-right{
    position: relative;
    transform: translateX(100vw);
    transition: all 1s ease;
    &.faster{
      transition: all 0.5s ease;
    }
    &.slow{
      transition: all 2s ease;
    }
    &.slower{
      transition: all 3s ease;
    }
  }
}
@media screen and (min-width: 641px) {
  h1{
    margin-top: 80px;
  }
  .content-section{
    & h2, & h1{
      font-size: 60px;
      scroll-margin: 254px;
    }
    &.revealed{
      & h2, & h1{
        scroll-margin: 104px;
      }
    }
  }
}