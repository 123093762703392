@use "../../utils/variables" as v;

.header{
    background-color: v.$backgroundA;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
	-webkit-border-radius: 0 0 8px 8px;
	-moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
    position: fixed !important;
    bottom: 0;
    height: 54px;
    width: 100%;
    z-index: 3;
    animation: fadein 1s ease;
    & > nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.3em 0.5em;
        & ul{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: row;
            position: relative;
            top: 4px;
            width: calc(100% + 20px);
            align-content: center;
            &.desktop-page-links{
                display: none;
                border-right: 8px solid v.$backgroundC;
                background: linear-gradient(to right, v.$backgroundA 20%, v.$backgroundC);
                justify-content: flex-end;
                & > li{
                    & > button{
                        transform: skew(-20deg);
                        border-radius: 0;
                        padding: 2px 8px;
                        color: v.$colorC;
                        & .MuiTouchRipple-child{
                            background-color: v.$colorC;
                        }
                        & > .link-text{
                            display: block;
                            transform: skew(20deg);
                            color: v.$colorC;
                            text-decoration: none;
                            font-size: 18px;
                        }
                        &:hover{
                            box-shadow: 0px 0px 8px 4px v.$blendDRxl;
                        }
                    }
                    &:nth-child(2n){
                        & > button{
                            background-color: v.$backgroundC;
                            &:hover{
                                background-color: v.$backgroundATS;
                            }
                        }
                    }
                    &:nth-child(2n-1){
                        & > button{
                            background-color: v.$backgroundBTM;
                            &:hover{
                                background-color: v.$backgroundBTS;
                            }
                        }
                    }
                }
            }
            &.mobile-page-links{
                justify-content: space-around;
                & > li{
                    & .MuiTouchRipple-child{
                        background-color: v.$colorC;
                    }
                    & .label-icon{
                        width: 0;
                        height: 0;
                        display: block;
                        overflow: visible;
                        position: relative;
                        top: -12px;
                        font-size: 0.5rem;
                    }
                    &[aria-label="Link-To-Home"]{
                        & > button{
                            & > span > svg{
                                fill: v.$colorB;
                            }
                            & .label-icon{
                                transform: translateX(-1.2px);
                            }
                        }
                    }
                    &[aria-label="Link-To-Experience"]{
                        & > button{
                            & > span > svg{
                                fill: v.$colorD;
                            }
                            & .label-icon{
                                transform: translateX(-16.4px);
                            }
                        }
                    }
                    &[aria-label="Link-To-Projects"]{
                        & > button{
                            & > span > svg{
                                fill: v.$colorC;
                            }
                            & .label-icon{
                                transform: translateX(-11.6px);
                            }
                        }
                    }
                    &[aria-label="Link-To-Contact"]{
                        & > button{
                            & > span > svg{
                                fill: v.$colorB;
                            }
                            & .label-icon{
                                transform: translateX(-9px);
                            }
                        }
                    }
                }
            }
        }
    }
}
.logo-link{
    display: flex;
    justify-content: center;
    z-index: 2;
    height: 51px;
    cursor: pointer;
    & > svg{
        position: relative;
        top: 3px;
        height: 38px;
        cursor: pointer;
    }
}
.main-links{
    width: 100%;
    z-index: 1;
    background-color: v.$backgroundA;
    border-radius: 8px;
    height: 43px;
    padding: 6px 10px 2px 10px;
    position: relative;
    overflow: visible;
    left: -10px;
    top: -4px;
    transition: all 0.3s;
}

@media screen and (min-width: 641px){
    .header{
        position: sticky;
        top: 0;
        left: 0;
        & > nav ul.mobile-page-links{
            display: none;
        }
        & > nav ul.desktop-page-links{
            display: flex;
        }
    }
    .logo-link > svg{
        height: 51px;
        top: 0;
    }
}
@keyframes fadein{
    0%{
        opacity: 0%;
    } 100%{
        opacity: 100%;
    }
}