@use "../../utils/variables" as v;

.image-slider{
    max-width: 100%;
    & .l-r-image-navigation{
        position:absolute;
        width: 90%;
        height: v.$projectSlideHeight;
        max-width: v.$maxProjectWidth;
        z-index:1;
        & .navigate-container{
            position: relative;
            left: 0px;
            font-size: 50px;
            display: inline-block;
            height: 100%;
            padding: 0px 10px;
            transition: all 0.2s ease-in-out;
            background-color:#1f1f1f3f;
            cursor: pointer;
            &::selection{
                background-color:none;
            }
            & div{
                position:relative;
                transform: translateY(-50%);
                top: 50%;
                transition: all 0.2s ease-in-out;
            }
            &:last-child{
                float: right;
                left: unset;
                right: 0px;
            }
            &:hover{
                background-color:#1f1f1f6f;
                & div{
                    color: white;
                }
            }
        }
    }
    & .slides{
        height: v.$projectSlideHeight;
        transition:all 0.3s ease-in-out;
        & .slide{
            height: 100%;
            display: inline-block;
            & img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media screen and (max-width: 600px){
    .image-slider{
        & .l-r-image-navigation{
            height: v.$mobileProjectSlideHeight;
        }
        & .slides{
            height: v.$mobileProjectSlideHeight;
        }
    }
}