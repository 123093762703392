@use "../../utils/variables" as v;
.projects-container{
    & > div{
        &.MuiPaper-root{
            background: #1f1f1f;
            width:90%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            margin-bottom: 20px;
            max-width: v.$maxProjectWidth;
            height: calc(min(500px,50vw) - 36px);
        }
    }
    & .project-header{
        display: grid;
        grid-template-columns: 1fr auto;
        & .project-tray{
            display: flex;
            justify-content: end;
            align-content: center;
        }
        & h3{
            margin: 0px;
            padding: 3px;
            color: v.$colorA;
            font-size: 0.8rem;
            align-content: center;
            background: #3f3f3f;
        }
    }
}
.project-footer{
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 0px 5px #ff0000;
    justify-content: center;
    align-content: center;
    display: flex;
    gap: 15px;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    & a{
        text-decoration: none;
    }
}

@media screen and (min-width: 980px){
    .project-footer{
        display: flex;
        gap: 15px;
        flex-direction: row;
    }
}

@media screen and (max-width: 600px){
    .projects-container{
        & > div{
            &.MuiPaper-root{
                height: calc(min(400px,80vw) - 36px);
            }
        }
    }
}