@use "../../utils/variables" as v;
section.contact-us-area{
    padding: 50px 100px;
}
.contact-content h2:not(.revealed h2){
    scroll-margin: 138px;
}
.contact-us-area{
    position: relative;
    /*min-height: 100vh;*/
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0px;
    & > .contact-content{
        max-width: 800px;
        text-align: center;
        & > p{
            font-weight: 400;
            color: v.$colorA;
            text-shadow: 0px 0px 5px v.$colorD;
        }
    }
    background-color: #00000055;
}
.container{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    & > .contactInfo{
        display: flex;
        flex-direction: column;
        width: 260px;
        margin-left: auto;
        margin-right: auto;
        & > .box{
            position: relative;
            padding: 20px 0;
            display: flex;
            align-items: center;
            & > .icon{
                min-width: 60px;
                height: 60px;
                background: v.$colorA;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 22px;
                & > svg{
                    fill: v.$backgroundD;
                }
            }
            & > .text{
                display: flex;
                margin-left: 20px;
                font-size: 16px;
                color: v.$colorA;
                flex-direction: column;
                font-weight: 400;
                text-shadow: 0px 0px 5px v.$colorD;
                & > h3{
                    font-weight: 500;
                    color: v.$colorC;
                    margin-top:12px;
                    margin-bottom:12px;
                }
                & > a{
                    text-decoration: none;
                    text-align: center;
                    border-radius: 4px;
                    background: #ffffff20;
                    margin-top:8px;
                    margin-bottom:8px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    &:hover{
                        background: #ffffff30;
                    }
                }
                & > p{
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
            }
        }
    }
}
div.contactForm{
    padding: 40px;
}
.contactForm{
    padding: 40px;
    width: 100%;
    background-color: v.$backgroundATL;
    
    & .inputBox{
        position: relative;
        width: 100%;
        margin-top: 10px;
        & input, & textarea{
            width: calc(100% - 20px);
            padding: 5px 10px;
            font-size: 16px;
            margin: 10px 0;
            border: none;
            color: v.$colorA;
            border-bottom: 2px solid v.$colorC;
            outline: none;
            background-color: v.$backgroundA;
            resize: none;
        }
        & span{
            position: absolute;
            left: 0;
            padding: 5px 10px;
            font-size: 16px;
            margin: 10px 0;
            pointer-event: none;
            transition: 0.5s;
            color: #666;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        & input:focus ~ span, & input:valid ~ span, & textarea:focus ~ span, & textarea:valid ~ span{
            color: v.$colorC;
            text-shadow: 0px 0px 1px v.$colorB;
            font-size: 12px;
            transform: translateY(-20px);
            &.error{
                color: red;
                text-shadow: 0px 0px 1px #ff3333;
            }
        }
        & input[type="submit"]{
            width: 100%;
            /*background: v.$colorC;*/
            background: v.$colorCTL;
            box-shadow: 0px 0px 8px v.$colorB;
            color: v.$colorA;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            padding: 10px;
            font-size: 18px;
            transition: all 0.2s ease-in-out;
            &:hover{
                background: v.$colorC;
            }
            &.disabled{
                background: v.$backgroundATS;
                &:hover{
                    background: v.$backgroundATS;
                }
            }
        }
    }
}

.error-msg{
    color: red;
    font-size: 0.8rem;
}
.secure-msg{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: v.$colorC;
    margin-top: 10px;
    & svg{
        color: v.$colorC;
        fill: v.$colorC;
    }
    & span{
        color: v.$colorC;
    }
}

.inputBox{
    background-color: v.$backgroundA;
    & input{
        background-color: v.$backgroundA;
        &.error{
            color: v.$colorD;
        }
    }
    & textarea{
        &.error{
            color: v.$colorD;
        }
    }
    &.submit-btn{
        margin-top: 15px;
        border-radius: 4px;
        & input{
            margin: 0;
        }
    }
}

@media screen and (min-width: 320px) {
    section.contact-us-area{
        padding: 0px 20px;
    }
}
@media screen and (min-width: 481px) {
    
}
@media screen and (min-width: 641px) {
    
}
@media screen and (min-width: 981px) {
    .container{
        margin-top: 30px;
        flex-direction: row;
    }
    .contactForm{
        width: 40%;
    }
}
@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 1281px) {
    
}