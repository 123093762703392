@use "../../utils/variables" as v;
.introduction-section{
    & h1{
        animation: fadeinhalf 1s ease;
        opacity: 1;
    }
    & .slide-in-from-right{
        animation: slideinfromright 1.2s ease;
        opacity: 1;
    }
    transform: translateY(0px);
}
.summary-card{
    box-shadow: inset 0 0 60px -12px gray;
    display: grid;
    grid-template-rows: auto auto;
    & > div:first-child{
        grid-row: 2;
    }
    & > div:last-child{
        grid-row: 1;
    }
    & strong{
        color: v.$colorC;
    }
}
.pfp-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    & img{
        border-radius: 50%;
        width: 160px;
    }
    & p{
        margin: 5px 0 15px 0;
        padding: 0;
        font-size: 20px;
        color: v.$colorA;
    }
}
.content-information{
    padding: 32px 40px;
}
.pfp-accounts{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: inset 0 0 30px -6px gray;
    & > .row-accounts{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 1.6px 0;
        & > a.pfp-icon{
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px;
            border-radius: 3px;
            transition: all 0.3s linear;
            cursor: pointer;
            &:hover{
                background-color:#80808080;
            }
        }
    }
    & svg{
        width: 50px;
    }
}
.intro-divider-line{
    border-bottom: 1px solid white;
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-top:26px;
    height: 0;
    & > .divider-text{
        position: relative;
        display: inline-block;
        padding: 0 4px;
        top: -10.8px;
        margin: 0;
        left: calc(50% - 121.15px);
        background: #1f1f1f;
    }
}
@media screen and (min-width: 961px){
    .summary-card{
        border-radius: 40px;
        margin: 3% 10%;
        grid-template-rows: unset;
        grid-template-columns: 2fr 1fr;
    }
    .pfp-accounts{
        border-radius: 3px 3px 40px 3px;
    }
}

@media screen and (min-width: 641px){
    .summary-card{
        grid-template-rows: unset;
        grid-template-columns: 2fr 1fr;
        height: 420px;
        & > div:first-child{
            background: linear-gradient(to right, transparent 90%, #ffffff50);
            border-right: 1px solid white;
            grid-row: unset;
        }
        & > div:last-child{
            background: linear-gradient(to left, transparent 80%, #ffffff50);
            grid-row: unset;
        }
    }
}

@keyframes fadeinhalf{
  0%{
    opacity: 0%;
  } 50%{
    opacity: 0%;
  } 100%{
    opacity: 100%;
  }
}
@keyframes slideinfromright{
    0%{
        opacity: 0%;
    } 50%{
        opacity: 0%;
        transform: translateX(100vw);
    } 100%{
        opacity: 100%;
        transform: 0px;
    }
}