$backgroundA: #3F3F3F;
$backgroundB: #330000;
$backgroundC: #333333;
$backgroundD: #000000; /*#1A4C39;*/
$backgroundATL: #000000bd;
$backgroundBTL: #330000bd;
$backgroundCTL: #000044bd;
$backgroundDTL: #000000bd; /*#1A4C39;*/
$backgroundATM: #0000007f;
$backgroundBTM: #3300007f;
$backgroundCTM: #0000447f;
$backgroundDTM: #0000007f; /*#1A4C39;*/
$backgroundATS: #00000063;
$backgroundBTS: #33000063;
$backgroundCTS: #00004463;
$backgroundDTS: #00000063; /*#1A4C39;*/
$colorA: #ffffff;
$colorATXL: #ffffffde;
$colorATL: #ffffffbd;
$colorATM: #ffffff7f;
$colorATS: #ffffff63;
$colorB: #800000;
$colorBTL: #800000bd;
$colorBTM: #8000007f;
$colorBTS: #80000063;
$colorC: #FF6600;
$colorCTL: #FF6600bd;
$colorCTM: #FF66007f;
$colorCTS: #FF660063;
$colorD: #ff0000;
$colorDTL: #ff0000bd;
$colorDTM: #ff00007f;
$colorDTS: #ff000063;

$blendRxxs: #ffcccc10;
$blendRxs: #ffcccc30;
$blendRs: #ffcccc50;
$blendRm: #ffcccc70;
$blendRl: #ffcccc90;
$blendRxl: #ffccccB0;
$blendRxxl: #ffccccD0;
$blendGxxs: #ccffcc10;
$blendGxs: #ccffcc30;
$blendGs: #ccffcc50;
$blendGm: #ccffcc70;
$blendGl: #ccffcc90;
$blendGxl: #ccffccB0;
$blendGxxl: #ccffccD0;
$blendBxxs: #ccccff10;
$blendBxs: #ccccff30;
$blendBs: #ccccff50;
$blendBm: #ccccff70;
$blendBl: #ccccff90;
$blendBxl: #ccccffB0;
$blendBxxl: #ccccffD0;

$blendDRxxs: #33000010;
$blendDRxs: #33000030;
$blendDRs: #33000050;
$blendDRm: #33000070;
$blendDRl: #33000090;
$blendDRxl: #330000B0;
$blendDRxxl: #330000D0;
$blendDGxxs: #00330010;
$blendDGxs: #00330030;
$blendDGs: #00330050;
$blendDGm: #00330070;
$blendDGl: #00330090;
$blendDGxl: #003300B0;
$blendDGxxl: #003300D0;
$blendDBxxs: #00003310;
$blendDBxs: #00003330;
$blendDBs: #00003350;
$blendDBm: #00003370;
$blendDBl: #00003390;
$blendDBxl: #000033B0;
$blendDBxxl: #000033D0;

$maxProjectWidth: 840px;
$projectSlideHeight: calc(min(500px, 50vw) - 70px);
$mobileProjectSlideHeight: calc(min(400px, 80vw) - 70px);